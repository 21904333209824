import React from 'react'
import classNames from 'classnames'
import { Link, useLocation, useMatch } from 'react-router-dom'
import { DASHBOARD_SIDEBAR_LINKS, APPROVAL_SIDEBAR_LINKS, APPROVED_SIDEBAR_LINKS, CAMPAIGN_SIDEBAR_LINKS } from './sidebar/sidebarlist'
import { HiOutlineLogout, HiOutlineX } from 'react-icons/hi'

const linkClass = 'flex items-center gap-2 font-light px-3 py-2 hover:no-underline  rounded text-base'

export default function Sidebar({ isSidebarOpen, toggleSidebar }) {
    return (
        <div className="bg-gray-800 h-[100dvh] w-[220px] p-2 flex flex-col z-50 overflow-y-auto">
            <div className="flex items-center justify-between gap-2 px-1 py-3">
                <h1 className="text-white text-lg">Dashboard</h1>
                <button
                    onClick={toggleSidebar}
                    className="lg:hidden p-1 text-white hover:bg-gray-700 rounded-md"
                >
                    <HiOutlineX fontSize={24} />
                </button>
            </div>
            <div className="py-6 flex flex-1 flex-col gap-2">
                {DASHBOARD_SIDEBAR_LINKS.map((link) => (
                    <SidebarLink key={link.key} link={link} />
                ))}
                <div className="mt-2">
                    <h1 className="text-[12px] font-medium text-gray-400 mt-2 p-2">Request Management</h1>
                    {APPROVAL_SIDEBAR_LINKS.map((link) => (
                        <SidebarLink className="pt-2" key={link.key} link={link} />
                    ))}
                </div>
                <div className="mt-2">
                    <h1 className="text-[12px] font-medium text-gray-400 mt-2 p-2">Approved Management</h1>
                    {APPROVED_SIDEBAR_LINKS.map((link) => (
                        <SidebarLink className="pt-2" key={link.key} link={link} />
                    ))}
                </div>
                <div className="mt-2">
                    <h1 className="text-[12px] font-medium text-gray-400 mt-2 p-2">Campaign Management</h1>
                    {CAMPAIGN_SIDEBAR_LINKS.map((link) => (
                        <SidebarLink className="pt-2" key={link.key} link={link} />
                    ))}
                </div>
            </div>

            <div className="flex flex-col gap-0.5 pt-2 border-t border-neutral-700">
                <Link to="/">
                    <div className={classNames(linkClass, 'cursor-pointer text-red-500')}>
                        <span className="text-xl">
                            <HiOutlineLogout />
                        </span>
                        Logout
                    </div>
                </Link>
            </div>
        </div>
    )
}

function SidebarLink({ link }) {
    const { pathname } = useLocation()
    const matchRoot = useMatch(link.path)

    return (
        <div className="pt-2">
            <Link
                to={link.path}
                className={classNames(
                    pathname === link.path || (matchRoot && pathname === '/')
                        ? 'bg-red-500 text-white text-sm'
                        : 'text-white text-sm hover:bg-red-500 ',
                    linkClass
                )}
            >
                <span className="text-xl">{link.icon}</span>
                {link.label}
            </Link>

            {link.submenu && (pathname.startsWith(link.path) || matchRoot) && (
                <div className="ml-6 text-sm">
                    {link.submenu.map((submenuLink) => (
                        <Link
                            key={submenuLink.key}
                            to={submenuLink.path}
                            className={classNames(
                                pathname === submenuLink.path ? 'bg-red-500 text-white text-sm' : 'text-white text-sm',
                                linkClass
                            )}
                        >
                            <span className="text-xl">{submenuLink.icon}</span>
                            {submenuLink.label}
                        </Link>
                    ))}
                </div>
            )}
        </div>
    )
}
