import React from "react";
import Modal from "react-modal";
import { FaTimes } from "react-icons/fa";

export default function StatusModal({ isOpen, onRequestClose, campaign }) {
    if (!campaign) return null;

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="fixed inset-0 flex items-center justify-center z-50 p-2 sm:p-4"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50"
        >
            <div className="bg-white rounded-lg shadow-lg w-full max-w-md h-[90vh] sm:h-auto flex flex-col">
                {/* Header */}
                <div className="flex justify-between items-center p-3 sm:p-4 border-b sticky top-0 bg-white">
                    <h2 className="text-base sm:text-xl font-bold text-gray-800 pr-2">
                        Interested Users for {campaign.name}
                    </h2>
                    <button
                        onClick={onRequestClose}
                        className="p-1.5 hover:bg-gray-100 rounded-full transition-colors"
                    >
                        <FaTimes className="w-4 h-4 sm:w-5 sm:h-5 text-gray-500" />
                    </button>
                </div>

                {/* Content */}
                <div className="flex-1 overflow-y-auto p-3 sm:p-4">
                    {campaign.interestedUsers && campaign.interestedUsers.length > 0 ? (
                        <div className="space-y-3">
                            {campaign.interestedUsers.map((user, index) => (
                                <div
                                    key={index}
                                    className="bg-white border rounded-lg p-3 sm:p-4 shadow-sm hover:shadow-md transition-shadow"
                                >
                                    <div className="space-y-2">
                                        <div className="flex flex-col sm:flex-row sm:items-center">
                                            <span className="text-sm font-semibold text-gray-600 min-w-[60px]">Name:</span>
                                            <span className="text-sm text-gray-800 break-all">{user.name}</span>
                                        </div>
                                        <div className="flex flex-col sm:flex-row sm:items-center">
                                            <span className="text-sm font-semibold text-gray-600 min-w-[60px]">Mobile:</span>
                                            <span className="text-sm text-gray-800 break-all">{user.mobile_number}</span>
                                        </div>
                                        <div className="flex flex-col sm:flex-row sm:items-center">
                                            <span className="text-sm font-semibold text-gray-600 min-w-[60px]">Email:</span>
                                            <span className="text-sm text-gray-800 break-all">{user.email}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="flex items-center justify-center h-32">
                            <p className="text-gray-500 text-center text-sm sm:text-base">
                                No interested users found for this campaign.
                            </p>
                        </div>
                    )}
                </div>

                {/* Footer */}
                <div className="p-3 sm:p-4 border-t sticky bottom-0 bg-white">
                    <button
                        onClick={onRequestClose}
                        className="w-full bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-lg transition-colors text-sm sm:text-base"
                    >
                        Close
                    </button>
                </div>
            </div>
        </Modal>
    );
}
