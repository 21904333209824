import React from 'react'
import DashboardStatsGrid from '../components/DashboardStatsGrid'

export default function Dashboard() {
    return (
        <div className="flex flex-col gap-4">
            <div className="px-2 sm:px-3">
                <h1 className="text-lg sm:text-xl font-medium">Hello, Admin</h1>
            </div>
            <div className="px-2 sm:px-3">
                <DashboardStatsGrid />
            </div>
        </div>
    )
}
