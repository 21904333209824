import { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from '../../constant/constance';
import DataTable from 'react-data-table-component';
import { ImEye, ImCross, ImInfo, ImSwitch } from "react-icons/im";
import { toast } from 'react-toastify';
import { PuffLoader } from 'react-spinners';
import CampaignDetailsModal from '../../components/shared/CampaignDetailsModal';
import StatusModal from '../../components/shared/StatusModal';

const Campaignlist = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdated, setIsUpdated] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [statusModalOpen, setStatusModalOpen] = useState(false);
    const [toggleState, setToggleState] = useState({});

    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            sortable: true,
            width: '50px',
        },
        {
            name: 'Name',
            selector: (row) => row.name,
            style: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '200px'
            }
        },
        {
            name: 'Brand Name',
            selector: (row) => row.brandname,
            style: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '200px'
            }
        },
        {
            name: 'Description',
            selector: (row) => row.description,
            style: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '300px'
            }
        },
        {
            name: 'Action',
            cell: (row) => (
                <div className="flex justify-center items-center gap-x-2">
                    <button
                        type="button"
                        className="rounded-md px-2 py-2 font-semibold text-red-600 shadow-sm"
                        onClick={() => deleteCampaign(row._id)}
                    >
                        <ImCross />
                    </button>
                    <button
                        type="button"
                        className="rounded-md px-2 py-2 font-semibold text-green-600 shadow-sm"
                        onClick={() => openModal(row)}
                    >
                        <ImEye />
                    </button>
                    <button
                        type='button'
                        className='rounded-md px-2 py-2 font-semibold text-blue-600 shadow-sm'
                        onClick={() => openStatusModal(row)}
                    >
                        <ImInfo />
                    </button>
                    <button
                        type='button'
                        className={`flex justify-center items-center rounded-md px-2 py-2 font-semibold w-20 ${toggleState[row._id] ? 'text-green-600' : 'text-red-600'} shadow-sm`}
                        onClick={() => toggleCampaignStatus(row._id)}
                    >
                        <ImSwitch className='mr-2' /> {toggleState[row._id] ? 'Open' : 'Close'}
                    </button>
                </div>
            ),
            width: '120px',
            style: {
                justifyContent: 'center',
            },
        }
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/campaign/get_all_campain`);
                if (response.status === 200) {
                    setData(response.data);
                    const initialToggleState = {};
                    response.data.forEach(campaign => {
                        initialToggleState[campaign._id] = campaign.status === 'open';
                    });
                    setToggleState(initialToggleState);
                } else {
                    toast.error('Failed to fetch data from the server');
                }
            } catch (error) {
                toast.error('Error fetching data');
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [isUpdated]);

    const deleteCampaign = async (campaignId) => {
        try {
            const response = await axios.delete(`${API_URL}/api/campaign/delete_campaign/${campaignId}`);
            if (response.status === 200) {
                toast.success(response.data.message);
                setIsUpdated((prev) => !prev);
            }
        } catch (error) {
            toast.error('Error deleting campaign');
            console.error('Error:', error);
        }
    };

    const openModal = (campaign) => {
        setSelectedCampaign(campaign);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedCampaign(null);
        setIsModalOpen(false);
    };

    const openStatusModal = (campaign) => {
        setSelectedCampaign(campaign);
        setStatusModalOpen(true);
    };

    const closeStatusModal = () => {
        setSelectedCampaign(null);
        setStatusModalOpen(false);
    };

    const toggleCampaignStatus = async (campaignId) => {
        const newStatus = toggleState[campaignId] ? 'closed' : 'open';
        try {
            const response = await axios.put(`${API_URL}/api/campaign/update_status/${campaignId}`, { status: newStatus });
            if (response.status === 200) {
                toast.success(response.data.message);
                setToggleState((prevState) => ({
                    ...prevState,
                    [campaignId]: !prevState[campaignId],
                }));
            }
        } catch (error) {
            toast.error('Error toggling campaign status');
            console.error('Error:', error);
        }
    };

    // Mobile card component with improved responsiveness
    const MobileCard = ({ data, index }) => (
        <div className="bg-white p-1.5 sm:p-3 rounded-lg shadow-sm border border-gray-200 w-full min-w-[280px] max-w-full break-words">
            {/* Header Section */}
            <div className="mb-2 sm:mb-4">
                {/* Index and Actions Row */}
                <div className="flex flex-col gap-2 sm:gap-3 w-full">
                    <span className="text-sm sm:text-base font-semibold text-gray-600">#{index + 1}</span>
                    {/* Action Buttons */}
                    <div className="grid grid-cols-2 sm:grid-cols-4 gap-2 sm:gap-3 w-full">
                        <button
                            type="button"
                            className="flex items-center justify-center rounded py-2 px-2 sm:px-3 font-semibold text-red-600 hover:bg-red-50 border border-red-200 transition-colors"
                            onClick={() => deleteCampaign(data._id)}
                        >
                            <ImCross className="w-3 h-3 sm:w-4 sm:h-4" />
                            <span className="ml-2 text-xs sm:text-sm">Delete</span>
                        </button>
                        <button
                            type="button"
                            className="flex items-center justify-center rounded py-2 px-2 sm:px-3 font-semibold text-green-600 hover:bg-green-50 border border-green-200 transition-colors"
                            onClick={() => openModal(data)}
                        >
                            <ImEye className="w-3 h-3 sm:w-4 sm:h-4" />
                            <span className="ml-2 text-xs sm:text-sm">View</span>
                        </button>
                        <button
                            type="button"
                            className="flex items-center justify-center rounded py-2 px-2 sm:px-3 font-semibold text-blue-600 hover:bg-blue-50 border border-blue-200 transition-colors"
                            onClick={() => openStatusModal(data)}
                        >
                            <ImInfo className="w-3 h-3 sm:w-4 sm:h-4" />
                            <span className="ml-2 text-xs sm:text-sm">Info</span>
                        </button>
                        <button
                            type="button"
                            className={`flex items-center justify-center rounded py-2 px-2 sm:px-3 font-semibold transition-colors ${toggleState[data._id]
                                    ? 'text-green-600 hover:bg-green-50 border border-green-200'
                                    : 'text-red-600 hover:bg-red-50 border border-red-200'
                                }`}
                            onClick={() => toggleCampaignStatus(data._id)}
                        >
                            <ImSwitch className="w-3 h-3 sm:w-4 sm:h-4" />
                            <span className="ml-2 text-xs sm:text-sm">
                                {toggleState[data._id] ? 'Open' : 'Close'}
                            </span>
                        </button>
                    </div>
                </div>
            </div>

            {/* Content Section */}
            <div className="space-y-1.5 sm:space-y-2.5">
                <DataRow label="Name" value={data.name} />
                <DataRow label="Brand Name" value={data.brandname} />
                <DataRow label="Description" value={data.description} />
            </div>
        </div>
    );

    // Helper component for data rows
    const DataRow = ({ label, value }) => (
        <div className="flex flex-col gap-0.5">
            <span className="text-[10px] xxs:text-[11px] sm:text-sm font-medium text-gray-500">
                {label}
            </span>
            <span className="text-[11px] xxs:text-xs sm:text-base text-gray-900 break-words line-clamp-2">
                {value || '-'}
            </span>
        </div>
    );

    return (
        <div className="p-2 sm:p-4 relative">
            {isLoading ? (
                <div className="flex justify-center items-center h-full">
                    <PuffLoader size={60} color={"#4A90E2"} />
                </div>
            ) : (
                <>
                    {/* Mobile/Tablet View */}
                    <div className="xl:hidden">
                        <h1 className="text-lg sm:text-xl font-semibold mb-3 sm:mb-4">Campaign List</h1>
                        <div className="space-y-3 sm:space-y-4">
                            {data.map((item, index) => (
                                <MobileCard key={item._id} data={item} index={index} />
                            ))}
                        </div>
                    </div>

                    {/* Desktop View */}
                    <div className="hidden xl:block">
                        <DataTable
                            title={<h1 className="text-xl font-semibold">Campaign List</h1>}
                            columns={columns}
                            data={data}
                            pagination
                            responsive
                            customStyles={{
                                table: {
                                    style: {
                                        minWidth: '900px',
                                    },
                                },
                                tableWrapper: {
                                    style: {
                                        overflowX: 'auto',
                                    },
                                },
                                rows: {
                                    style: {
                                        minHeight: '72px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    },
                                },
                                headCells: {
                                    style: {
                                        paddingLeft: '8px',
                                        paddingRight: '8px',
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    },
                                },
                                cells: {
                                    style: {
                                        paddingLeft: '8px',
                                        paddingRight: '8px',
                                        textAlign: 'center',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    },
                                },
                            }}
                        />
                    </div>

                    {/* Modals */}
                    <CampaignDetailsModal
                        isOpen={isModalOpen}
                        onRequestClose={closeModal}
                        campaign={selectedCampaign}
                    />
                    <StatusModal
                        isOpen={statusModalOpen}
                        onRequestClose={closeStatusModal}
                        campaign={selectedCampaign}
                    />
                </>
            )}
        </div>
    );
};

export default Campaignlist;
