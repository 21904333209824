import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom'
import Layout from './components/shared/Layout'
import Dashboard from './pages/Dashboard'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Login from './components/Auth/login'
import { useState, useEffect } from 'react'
import Contactus from './pages/Contactus/Contactus'
import Brand from './pages/Approval/Brand'
import Creator from './pages/Approval/Creator'
import Brandapprove from './pages/Approved/Brandapprove'
import Createcampaign from './pages/campaign/Createcampaign'
import Campaignlist from './pages/campaign/Campaignlist'
import Partnerapprove from './pages/Approved/partnerapprove'
import { PopupProvider } from 'react-popup-manager'
import PuffLoader from "react-spinners/PuffLoader";
import axios from 'axios';
import { API_URL } from './constant/constance';
import Modal from 'react-modal';

Modal.setAppElement('#root');

function AdminRedirect() {
    window.location.replace('https://bookmesocial.com')
    return null
}

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/auth/check?role=admin`, { withCredentials: true });
                const { authenticated, role } = response.data;
                if (authenticated && role === "admin") {
                    setIsAuthenticated(true);
                } else {
                    setIsAuthenticated(false);
                }
            } catch (error) {
                setIsAuthenticated(false);
            }
        };
        checkAuth();
    }, []);

    const logout = () => {
        setIsAuthenticated(false);
        document.cookie = "admin_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    if (isAuthenticated === null) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <PuffLoader color="#4A90E2" size={60} />
            </div>
        );
    }

    return (
        <Router>
            <Routes>
                <Route index element={<Login setIsAuthenticated={setIsAuthenticated} />} />
                <Route path="/" element={<Layout isAuthenticated={isAuthenticated} logout={logout} />}>
                    <Route
                        index
                        element={isAuthenticated ? <Navigate to="admin-dashboard" /> : <Navigate to="/" />}
                    />
                    <Route
                        path="admin-dashboard"
                        element={isAuthenticated ? <Dashboard /> : <Navigate to="/" />}
                    />
                    <Route
                        path="brand"
                        element={isAuthenticated ? <Brand /> : <Navigate to="/" />}
                    />
                    <Route
                        path="creator"
                        element={isAuthenticated ? <Creator /> : <Navigate to="/" />}
                    />
                    <Route
                        path="brandapprove"
                        element={isAuthenticated ? (
                            <PopupProvider>
                                <Brandapprove />
                            </PopupProvider>
                        ) : (
                            <Navigate to="/" />
                        )}
                    />
                    <Route
                        path="creatorapprove"
                        element={isAuthenticated ? (
                            <PopupProvider>
                                <Partnerapprove />
                            </PopupProvider>
                        ) : (
                            <Navigate to="/" />
                        )}
                    />
                    <Route
                        path="createcampaign"
                        element={isAuthenticated ? <Createcampaign /> : <Navigate to="/" />}
                    />
                    <Route
                        path="campaignlist"
                        element={isAuthenticated ? <Campaignlist /> : <Navigate to="/" />}
                    />
                    <Route
                        path="contactus"
                        element={isAuthenticated ? <Contactus /> : <Navigate to="/" />}
                    />
                    <Route
                        path="homepage"
                        element={isAuthenticated ? <AdminRedirect /> : <Navigate to="/" />}
                    />
                </Route>
            </Routes>
            <ToastContainer autoClose={3000} />
        </Router>
    )
}

export default App;