import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from './Sidebar'
import Header from './Header'

export default function Layout({ isAuthenticated, logout }) {
	const [isSidebarOpen, setSidebarOpen] = useState(false);

	const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);

	return (
		<div className="bg-white h-[100dvh] w-screen overflow-hidden flex flex-row">
			{/* Mobile overlay */}
			{isSidebarOpen && (
				<div
					className="fixed inset-0 z-20 bg-black bg-opacity-50 lg:hidden"
					onClick={() => setSidebarOpen(false)}
				/>
			)}
			<div className={`fixed z-30 h-full lg:static lg:translate-x-0 transition-transform duration-300 ease-in-out ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
				}`}>
				<Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
			</div>
			<div className="flex flex-col flex-1">
				<Header
					logout={logout}
					toggleSidebar={toggleSidebar}
					isSidebarOpen={isSidebarOpen}
				/>
				<div className="flex-1 p-4 min-h-0 overflow-auto">
					<Outlet />
				</div>
			</div>
		</div>
	);
}
