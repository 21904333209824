import { HiOutlinePhone, HiOutlineCursorClick, HiClipboardList, HiOutlineBriefcase, HiOutlineSpeakerphone, HiUserAdd, HiUser } from 'react-icons/hi'

export const DASHBOARD_SIDEBAR_LINKS = [
    {
        key: 'dashboard',
        label: 'Home',
        path: '/admin-dashboard',

    }
]

export const APPROVAL_SIDEBAR_LINKS = [
    {
        key: 'Brand',
        label: 'Brand',
        path: '/brand',
        icon: <HiOutlineSpeakerphone />
    },
    {
        key: 'Partner',
        label: 'Partner',
        path: '/creator',
        icon: <HiUserAdd />
    },
]

export const APPROVED_SIDEBAR_LINKS = [
    {
        key: 'Brand',
        label: 'Brand',
        path: '/brandapprove',
        icon: <HiOutlineSpeakerphone />
    },
    {
        key: 'Creator',
        label: 'Partner',
        path: '/creatorapprove',
        icon: <HiUser />
    }
]

export const CAMPAIGN_SIDEBAR_LINKS = [
    {
        key: 'Createcampaign',
        label: 'Create Campaign',
        path: '/createcampaign',
        icon: <HiOutlineCursorClick />
    },
    {
        key: 'Creator',
        label: 'Campaign List',
        path: '/campaignlist',
        icon: <HiClipboardList />
    }
]
