import React, { useEffect, useState } from 'react'
import { IoPeople } from 'react-icons/io5'
import axios from 'axios'
import { API_URL } from '../constant/constance';
import TransactionChart from './TransactionChart';

export default function DashboardStatsGrid() {
    const [totalBrand, setTotalBrand] = useState()
    const [totalPartner, setTotalPartner] = useState()

    const data = [
        {
            name: 'Total Brand Request',
            value: totalBrand
        },
        {
            name: 'Total Patner Request',
            value: totalPartner
        }
    ]

    useEffect(() => {
        const fetchBrandRequest = async () => {
            try {
                const res = await axios.get(`${API_URL}/api/brandreg/total_brand_request`)
                setTotalBrand(res.data.count)
            } catch (error) {
                console.log(error)
            }
        }
        fetchBrandRequest()
    }, [totalBrand]);

    useEffect(() => {
        const fetchPartnerRequest = async () => {
            try {
                const res = await axios.get(`${API_URL}/api/creatorreg/total_patner_request`)
                setTotalPartner(res.data.count)
            } catch (error) {
                console.log(error)
            }
        }
        fetchPartnerRequest()
    }, [totalPartner]);

    return (
        <div className="flex flex-col gap-4 sm:gap-6 md:gap-8">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
                <BoxWrapper>
                    <div className="flex items-center w-full">
                        <div className="rounded-full h-10 w-10 sm:h-12 sm:w-12 flex items-center justify-center bg-yellow-400 shrink-0">
                            <IoPeople className="text-xl sm:text-2xl text-white" />
                        </div>
                        <div className="pl-3 sm:pl-4 overflow-hidden">
                            <span className="text-xs sm:text-sm text-gray-500 font-light whitespace-nowrap">Total Brands Request</span>
                            <div className="flex items-center">
                                <strong className="text-lg sm:text-xl text-gray-700 font-semibold">{totalBrand}</strong>
                            </div>
                        </div>
                    </div>
                </BoxWrapper>
                <BoxWrapper>
                    <div className="flex items-center w-full">
                        <div className="rounded-full h-10 w-10 sm:h-12 sm:w-12 flex items-center justify-center bg-yellow-400 shrink-0">
                            <IoPeople className="text-xl sm:text-2xl text-white" />
                        </div>
                        <div className="pl-3 sm:pl-4 overflow-hidden">
                            <span className="text-xs sm:text-sm text-gray-500 font-light whitespace-nowrap">Total Partner Request</span>
                            <div className="flex items-center">
                                <strong className="text-lg sm:text-xl text-gray-700 font-semibold">{totalPartner}</strong>
                            </div>
                        </div>
                    </div>
                </BoxWrapper>
            </div>
        </div>
    )
}

function BoxWrapper({ children }) {
    return (
        <div className="bg-white rounded-sm p-3 sm:p-4 border border-gray-200 flex items-center shadow-sm hover:shadow-md transition-shadow duration-300">
            {children}
        </div>
    )
}
