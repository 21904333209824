import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { API_URL } from '../../constant/constance'
import DataTable from 'react-data-table-component'
import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa'
import { BsChatTextFill } from 'react-icons/bs'
import { usePopupManager } from 'react-popup-manager'
import { PopUpPatner } from '../../components/shared/PopUpPartner'
import { ImEye, ImCross } from "react-icons/im";

const Example = () => {
    const [patnerData, setPatnerData] = useState([])
    const [isupdated, setIsupdated] = useState(false)
    const [Detail, setDetail] = useState([])

    const [filterText, setFilterText] = useState('')
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false)

    const filteredItems = patnerData.filter(
        (item) => item.email_id && item.email_id.toLowerCase().includes(filterText.toLowerCase())
    )

    const socialMediaIcons = {
        instagram: (
            <FaInstagram
                title="Instagram"
                style={{
                    background: "linear-gradient(45deg, #f09433, #e6683c, #dc2743, #cc2366, #bc1888)",
                    borderRadius: "25%",
                    color: "white",
                }}
            />
        ),
        facebook: (
            <FaFacebook
                title="Facebook"
                style={{
                    color: "#3b5998",
                }}
            />
        ),
        others: (
            <BsChatTextFill
                title="others"
                style={{
                    color: "gray",
                }}
            />
        ),
        youtube: (
            <FaYoutube
                title="Youtube"
                style={{
                    color: "#FF0000",
                }}
            />
        ),
    };

    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            sortable: true,
            width: '77px'
        },
        {
            name: 'Name',
            selector: (row) => row.name,
            style: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '200px'
            }
        },
        {
            name: 'Email',
            selector: (row) => row.email_id,
            style: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '200px'
            }
        },
        {
            name: 'Phone Number',
            selector: (row) => row.mobile_number,
            style: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '200px'
            }
        },
        {
            name: 'Channal Name',
            selector: (row) => row.channel_site_name,
            style: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '200px'
            }
        },
        {
            name: 'Social Media',
            style: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '200px'
            },
            cell: (row) => (
                <div className="flex flex-wrap items-center gap-x-2 gap-y-1">
                    {row.social_media && row.social_media.length > 0 ? (
                        row.social_media.slice(0, 4).map((media, index) => (
                            <span
                                key={index}
                                className="bg-gray-200 px-2 py-1 rounded-md text-sm flex items-center"
                                title={media}
                            >
                                {socialMediaIcons[media] || media}
                            </span>
                        ))
                    ) : (
                        <span className="text-gray-500">No social media accounts</span>
                    )}
                </div>
            )
        },
        {
            name: 'Followers',
            selector: (row) => row.followers_range,
            style: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '200px'
            }
        },
        {
            name: 'Action',
            cell: (row) => (
                <div className="flex items-center gap-x-2">
                    <button
                        type="submit"
                        className="rounded-md px-2 py-2 font-semibold text-red-600 shadow-sm"
                        onClick={() => handleReject(row._id)}
                    >
                        <ImCross />
                    </button>
                    <button
                        type="submit"
                        className="rounded-md px-2 py-2 font-semibold text-green-600 shadow-sm"
                        onClick={() => handleDetails(row._id)}
                    >
                        <ImEye />
                    </button>
                </div>
            )
        }
    ]

    useEffect(() => {
        const fetchCreatorData = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/creatorreg/approved`)
                setPatnerData(response.data.data)
            } catch (error) {
                console.error('Error fetching creator data:', error)
            }
        }
        fetchCreatorData()
    }, [isupdated])

    const handleDetails = async (itemId) => {
        try {
            const response = await axios.get(`${API_URL}/api/creatorreg/Patner_details/${itemId}`)
            if (response.status === 200) {
                setDetail(response.data.data)
                openModal(response.data.data)
            }
        } catch (error) {
            toast.error(error.message)
            console.error('Error', error)
        }
    }

    const handleReject = async (itemId) => {
        try {
            const response = await axios.get(`${API_URL}/api/creatorreg/reject_creater/${itemId}`)
            console.log(response)
            if (response.status === 200) {
                toast.success(response.data.message)
                setIsupdated((prev) => !prev)
            }
        } catch (error) {
            toast.error(error.message)
            console.error('Error', error)
        }
    }

    const popupManager = usePopupManager()
    const openModal = (Data) => {
        popupManager.open(PopUpPatner, {
            data: Data,
        })
    }

    // Custom component for mobile/tablet view
    const MobileCard = ({ data, index }) => (
        <div className="bg-white p-3 sm:p-4 md:p-5 rounded-lg shadow-sm border border-gray-200 space-y-3 sm:space-y-4 overflow-hidden">
            {/* Header Section */}
            <div className="flex justify-between items-center">
                <span className="text-sm sm:text-base font-semibold text-gray-600">#{index + 1}</span>
                <div className="flex items-center gap-2">
                    <button
                        type="button"
                        className="rounded p-1.5 sm:p-2 text-red-600 hover:bg-red-50 transition-colors duration-200"
                        onClick={() => handleReject(data._id)}
                    >
                        <ImCross className="w-3 h-3 sm:w-4 sm:h-4" />
                    </button>
                    <button
                        type="button"
                        className="rounded p-1.5 sm:p-2 text-green-600 hover:bg-green-50 transition-colors duration-200"
                        onClick={() => handleDetails(data._id)}
                    >
                        <ImEye className="w-3 h-3 sm:w-4 sm:h-4" />
                    </button>
                </div>
            </div>

            {/* Content Section */}
            <div className="space-y-2.5 sm:space-y-3">
                <DataRow label="Name" value={data.name} />
                <DataRow label="Email" value={data.email_id} />
                <DataRow label="Phone Number" value={data.mobile_number} />
                <DataRow label="Channel Name" value={data.channel_site_name} />
                <DataRow
                    label="Social Media"
                    value={
                        <div className="flex flex-wrap items-center gap-1.5 mt-1">
                            {data.social_media && data.social_media.length > 0 ? (
                                data.social_media.slice(0, 4).map((media, index) => (
                                    <span
                                        key={index}
                                        className="bg-gray-200 px-1.5 py-0.5 sm:px-2 sm:py-1 rounded-md text-xs sm:text-sm flex items-center"
                                        title={media}
                                    >
                                        {socialMediaIcons[media] || media}
                                    </span>
                                ))
                            ) : (
                                <span className="text-gray-500 text-xs sm:text-sm">No social media accounts</span>
                            )}
                        </div>
                    }
                />
                <DataRow label="Followers" value={data.followers_range} />
            </div>
        </div>
    );

    // Helper component for each data row in mobile view
    const DataRow = ({ label, value }) => (
        <div className="flex flex-col gap-0.5 min-w-0"> {/* Added min-w-0 for proper text wrapping */}
            <span className="text-xs sm:text-sm font-medium text-gray-500">{label}</span>
            {React.isValidElement(value) ? (
                value
            ) : (
                <span className="text-sm sm:text-base text-gray-900 break-words">
                    {value || '-'}
                </span>
            )}
        </div>
    );

    return (
        <div className="flex flex-col gap-4 w-full transition-all duration-300">
            {/* Search Input */}
            <div className="px-2 sm:px-4">
                <input
                    type="text"
                    placeholder="Search by Email ID"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                    className="p-2 sm:p-3 text-sm sm:text-base border rounded-lg w-full max-w-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300"
                />
            </div>

            {/* Mobile/Tablet View with Grid Layout */}
            <div className="xl:hidden px-2 sm:px-4 transition-all duration-300">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 sm:gap-3 md:gap-4">
                    {(filterText.length > 0 ? filteredItems : patnerData).map((item, index) => (
                        <MobileCard key={item._id} data={item} index={index} />
                    ))}
                </div>
            </div>

            {/* Desktop View (Original Table) */}
            <div className="hidden xl:block px-2 sm:px-4 transition-all duration-300">
                <div className="border rounded shadow-sm overflow-hidden">
                    <DataTable
                        title="Partner"
                        columns={columns}
                        data={filterText.length > 0 ? filteredItems : patnerData}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle}
                        customStyles={{
                            table: {
                                style: {
                                    backgroundColor: 'white',
                                },
                            },
                            rows: {
                                style: {
                                    minHeight: '56px',
                                    fontSize: '14px',
                                    backgroundColor: 'white',
                                    '&:hover': {
                                        backgroundColor: '#f9fafb',
                                    },
                                    borderBottom: '1px solid #f0f0f0',
                                },
                            },
                            headRow: {
                                style: {
                                    backgroundColor: '#f8f9fa',
                                    minHeight: '48px',
                                    borderBottom: '2px solid #e5e7eb',
                                },
                            },
                            headCells: {
                                style: {
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    padding: '12px 24px',
                                },
                                firstChild: {
                                    paddingLeft: '24px',
                                },
                            },
                            cells: {
                                style: {
                                    padding: '12px 24px',
                                },
                                firstChild: {
                                    paddingLeft: '24px',
                                },
                            },
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default Example