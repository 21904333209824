import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import logo from '../../assets/logo (53).png'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from '../../constant/constance.js';

export default function Login({ setIsAuthenticated }) {
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState({
        username: '',
        password: '',
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const valadateLogin = () => {
        if (!formData.username.length) {
            toast.error("Username is required")
            return false
        };
        if (!formData.password.length) {
            toast.error("Password is required")
            return false
        };
        return true
    };

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (valadateLogin()) {
            try {
                const response = await axios.post(`${API_URL}/api/auth/login`, formData, { withCredentials: true })

                if (response.status === 200) {
                    toast.success(response.data.message);
                    setIsAuthenticated(true);
                    navigate("/admin-dashboard");
                }
            } catch (error) {
                toast.error(error.response.data.error)
                console.error('Error during login:', error)
            }
        }
    };

    return (
        <div className="login-main min-h-screen w-full">
            <ToastContainer />
            <div className="authentication-wrapper authentication-basic">
                <div className="authentication-inner py-4 sm:py-6">
                    <div className="card bg-white rounded-lg shadow-lg w-[90%] sm:w-[400px] mx-auto">
                        <div className="card-body card-custom">
                            <div className="flex flex-col text-center justify-center items-center gap-2 mb-4">
                                <img
                                    src={logo}
                                    alt="Logo"
                                    className="w-full max-w-[180px] sm:max-w-[200px] p-2 sm:p-4"
                                />
                            </div>

                            <p className="mb-6 text-center text-sm sm:text-base">
                                Admin Panel
                            </p>

                            <form
                                id="formAuthentication"
                                className="mb-3"
                                onSubmit={handleSubmit}
                            >
                                <div className="mb-4">
                                    <label htmlFor="username" className="block mb-2 text-sm sm:text-base">
                                        Username
                                    </label>
                                    <input
                                        type="text"
                                        className="w-full px-3 py-2 text-sm sm:text-base rounded border focus:outline-none focus:ring-2 focus:ring-indigo-500 placeholder:text-sm placeholder:opacity-60"
                                        id="username"
                                        name="username"
                                        value={formData.username}
                                        onChange={handleChange}
                                        placeholder="Enter your username"
                                        autoFocus
                                    />
                                </div>

                                <div className="mb-4">
                                    <label className="block mb-2 text-sm sm:text-base" htmlFor="password">
                                        Password
                                    </label>
                                    <div className="relative">
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            id="password"
                                            className="w-full px-3 py-2 text-sm sm:text-base rounded border focus:outline-none focus:ring-2 focus:ring-indigo-500 pr-10 placeholder:text-sm placeholder:opacity-60"
                                            name="password"
                                            value={formData.password}
                                            onChange={handleChange}
                                            placeholder="Enter your Password"
                                        />
                                        <button
                                            type="button"
                                            className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500"
                                            onClick={togglePasswordVisibility}
                                        >
                                            {showPassword ?
                                                <FiEyeOff className="w-4 h-4 sm:w-5 sm:h-5" /> :
                                                <FiEye className="w-4 h-4 sm:w-5 sm:h-5" />
                                            }
                                        </button>
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <Link to="" className="text-sm sm:text-base hover:underline">
                                        <small>Forgot Password?</small>
                                    </Link>
                                </div>

                                <div className="mb-4 flex justify-center">
                                    <button
                                        className="bg-green-600 rounded-lg px-4 py-2.5 text-white text-center w-52 text-sm sm:text-base font-medium hover:bg-green-700 transition-colors duration-200"
                                        type="submit"
                                    >
                                        Sign in
                                    </button>
                                </div>
                            </form>

                            <p className='text-center text-base sm:text-lg mt-6'>
                                <Link to="/homepage">
                                    <span className="cursor-pointer hover:underline">
                                        Back to Bookmesocial
                                    </span>
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
