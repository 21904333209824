import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { API_URL } from '../../constant/constance'
import { toast } from 'react-toastify'
import DataTable from 'react-data-table-component'
import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa'
import { BsChatTextFill } from 'react-icons/bs'
import { ImCheckmark, ImCross } from 'react-icons/im'

function Creator() {
    const [creatorData, setCreatorData] = useState([])
    const [isupdated, setIsupdated] = useState(false)
    const [filterText, setFilterText] = useState('')
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false)

    // Filtered data
    const filteredItems = creatorData.filter(
        (item) => item.email_id && item.email_id.toLowerCase().includes(filterText.toLowerCase())
    )

    const socialMediaIcons = {
        instagram: <FaInstagram title="Instagram" />,
        facebook: <FaFacebook title="Facebook" />,
        others: <BsChatTextFill title="others" />,
        youtube: <FaYoutube title="Youtube" />
    }


    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            sortable: true,
            width: '50px',
            compact: true,
            style: {
                paddingLeft: '24px',
            },
            headCell: {
                style: {
                    paddingLeft: '24px',
                },
            },
        },
        {
            name: 'Name',
            selector: (row) => row.name,
            wrap: true,
            minWidth: '220px',
            compact: true
        },
        {
            name: 'Email',
            selector: (row) => row.email_id,
            wrap: true,
            minWidth: '220px',
            compact: true
        },
        {
            name: 'Phone',
            selector: (row) => row.mobile_number,
            wrap: true,
            minWidth: '120px',
            compact: true
        },
        {
            name: 'Channel',
            selector: (row) => row.channel_site_name,
            wrap: true,
            minWidth: '150px',
            compact: true
        },
        {
            name: 'Social',
            minWidth: '180px',
            compact: true,
            cell: (row) => (
                <div className="flex flex-wrap items-center gap-1">
                    {row.social_media && row.social_media.length > 0 ? (
                        row.social_media.slice(0, 4).map((media, index) => (
                            <span
                                key={index}
                                className="bg-gray-200 px-1.5 py-0.5 rounded-md text-sm flex items-center"
                                title={media}
                            >
                                {socialMediaIcons[media] || media}
                            </span>
                        ))
                    ) : (
                        <span className="text-gray-500 text-sm">None</span>
                    )}
                </div>
            )
        },
        {
            name: 'Followers',
            selector: (row) => row.followers_range,
            wrap: true,
            minWidth: '120px',
            compact: true
        },
        {
            name: 'Action',
            minWidth: '100px',
            compact: true,
            cell: (row) => (
                <div className="flex items-center gap-1">
                    <button
                        type="button"
                        className="p-1.5 text-red-600 hover:bg-red-50 rounded"
                        onClick={() => handleReject(row._id)}
                    >
                        <ImCross size={12} />
                    </button>
                    <button
                        type="button"
                        className="p-1.5 text-green-600 hover:bg-green-50 rounded"
                        onClick={() => handleAprovel(row._id)}
                    >
                        <ImCheckmark size={12} />
                    </button>
                </div>
            )
        }
    ]

    const sendEmail = (userId) => {
        axios
            .post(`${API_URL}/api/creatorreg/send-email`, { user_id: userId })
            .then((response) => {
                console.log('Email sent successfully:', response.data)
                window.location.reload()
            })
            .catch((error) => {
                console.error('Error sending email:', error)
            })
    }

    useEffect(() => {
        const fetchCreatorData = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/creatorreg/creater`)
                setCreatorData(response.data.data)
            } catch (error) {
                console.error('Error fetching creator data:', error)
            }
        }
        fetchCreatorData()
    }, [isupdated])

    const handleAprovel = async (itemId) => {
        try {
            const response = await axios.get(`${API_URL}/api/creatorreg/aprover_creater/${itemId}`)
            if (response.status === 200) {
                toast.success(response.data.message)
                setIsupdated((prev) => !prev)
            }
        } catch (error) {
            toast.error(error.message)
            console.error('Error', error)
        }
    }

    const handleReject = async (itemId) => {
        try {
            const response = await axios.get(`${API_URL}/api/creatorreg/reject_creater/${itemId}`)
            console.log(response)
            if (response.status === 200) {
                toast.success(response.data.message)
                setIsupdated((prev) => !prev)
            }
        } catch (error) {
            toast.error(error.message)
            console.error('Error', error)
        }
    }

    const MobileCard = ({ data, index }) => (
        <div className="bg-white p-3 sm:p-4 rounded-lg shadow-sm border border-gray-200 transition-all duration-300 hover:shadow-md">
            <div className="flex justify-between items-center mb-3">
                <span className="text-sm font-semibold text-gray-600">#{index + 1}</span>
                <div className="flex items-center gap-2">
                    <button
                        type="button"
                        className="p-1.5 text-red-600 hover:bg-red-50 rounded"
                        onClick={() => handleReject(data._id)}
                    >
                        <ImCross className="w-3 h-3" />
                    </button>
                    <button
                        type="button"
                        className="p-1.5 text-green-600 hover:bg-green-50 rounded"
                        onClick={() => handleAprovel(data._id)}
                    >
                        <ImCheckmark className="w-3 h-3" />
                    </button>
                </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                <DataRow label="Name" value={data.name} />
                <DataRow label="Email" value={data.email_id} />
                <DataRow label="Phone" value={data.mobile_number} />
                <DataRow label="Channel" value={data.channel_site_name} />
                <DataRow label="Followers" value={data.followers_range} />
                <div className="sm:col-span-2">
                    <span className="text-xs font-medium text-gray-500 block mb-1">Social Media</span>
                    <div className="flex flex-wrap gap-1.5">
                        {data.social_media && data.social_media.length > 0 ? (
                            data.social_media.slice(0, 4).map((media, index) => (
                                <span
                                    key={index}
                                    className="bg-gray-100 px-2 py-1 rounded text-xs flex items-center gap-1"
                                    title={media}
                                >
                                    {socialMediaIcons[media] || media}
                                </span>
                            ))
                        ) : (
                            <span className="text-gray-500 text-xs">No social media accounts</span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );

    const DataRow = ({ label, value }) => (
        <div className="flex flex-col gap-0.5 transition-all duration-300">
            <span className="text-xs font-medium text-gray-500 transition-colors duration-300">{label}</span>
            <span className="text-sm text-gray-900 break-words transition-colors duration-300">{value || '-'}</span>
        </div>
    );

    return (
        <div className="flex flex-col gap-4 w-full">
            <div className="px-2 sm:px-4">
                <input
                    type="text"
                    placeholder="Search by Email ID"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                    className="p-2 border rounded w-full max-w-lg text-sm outline-none transition-all duration-300"
                />
            </div>

            {/* Mobile View */}
            <div className="xl:hidden px-2 sm:px-4 transition-all duration-300 ease-in-out">
                <div className="grid gap-3 sm:gap-4 transition-all duration-300">
                    {(filterText.length > 0 ? filteredItems : creatorData).map((item, index) => (
                        <MobileCard key={item._id} data={item} index={index} />
                    ))}
                </div>
            </div>

            {/* Desktop View */}
            <div className="hidden xl:block px-2 sm:px-4 transition-all duration-300 ease-in-out">
                <div className="border rounded shadow-sm overflow-hidden transition-all duration-300">
                    <DataTable
                        title="Creator"
                        columns={columns}
                        data={filterText.length > 0 ? filteredItems : creatorData}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle}
                        responsive
                        customStyles={{
                            table: {
                                style: {
                                    minWidth: '100%',
                                    transition: 'all 0.3s ease-in-out',
                                },
                            },
                            rows: {
                                style: {
                                    minHeight: '52px',
                                    fontSize: '13px',
                                    backgroundColor: 'white',
                                    transition: 'all 0.3s ease-in-out',
                                    '&:hover': {
                                        backgroundColor: '#f9fafb',
                                    },
                                    borderBottom: '1px solid #f0f0f0',
                                },
                            },
                            headRow: {
                                style: {
                                    backgroundColor: '#f8f9fa',
                                    minHeight: '48px',
                                    borderBottom: '2px solid #e5e7eb',
                                    transition: 'all 0.3s ease-in-out',
                                },
                            },
                            headCells: {
                                style: {
                                    fontSize: '13px',
                                    fontWeight: '600',
                                    padding: '12px 24px',
                                    transition: 'all 0.3s ease-in-out',
                                },
                                firstChild: {
                                    paddingLeft: '24px',
                                },
                            },
                            cells: {
                                style: {
                                    padding: '12px 24px',
                                    transition: 'all 0.3s ease-in-out',
                                },
                                firstChild: {
                                    paddingLeft: '24px',
                                },
                            },
                        }}
                        striped={false}
                        highlightOnHover
                    />
                </div>
            </div>
        </div>
    );
}

export default Creator
