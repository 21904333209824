import axios from 'axios'
import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import PuffLoader from 'react-spinners/PuffLoader'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { API_URL } from '../../constant/constance'

export default function CreateCampaign() {
    const [formData, setFormData] = useState({
        name: '',
        uploadimg: [],
        campaigns: '',
        apply_before: '',
        campaign_type: '',
        earnings: '',
        age_group: '',
        gender: '',
        language: '',
        peoples: '',
        channels: '',
        brandname: '',
        hint: '',
        description: '',
        brief: '',
        terms: ''
    })
    const [uploadProgress, setUploadProgress] = useState(0);
    const [loading, setLoading] = useState(false);

    const onDrop = (acceptedFiles) => {
        setFormData((prevData) => ({
            ...prevData,
            uploadimg: [...prevData.uploadimg, ...acceptedFiles]
        }))
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formDataToSend = new FormData()
        formDataToSend.append('name', formData.name)
        formDataToSend.append('campaigns', formData.campaigns)
        formDataToSend.append('apply_before', formData.apply_before)
        formDataToSend.append('campaign_type', formData.campaign_type)
        formDataToSend.append('earnings', formData.earnings)
        formDataToSend.append('age_group', formData.age_group)
        formDataToSend.append('gender', formData.gender)
        formDataToSend.append('language', formData.language)
        formDataToSend.append('peoples', formData.peoples)
        formDataToSend.append('channels', formData.channels)
        formDataToSend.append('brandname', formData.brandname)
        formDataToSend.append('hint', formData.hint)
        formDataToSend.append('description', formData.description)
        formDataToSend.append('brief', formData.brief)
        formDataToSend.append('terms', formData.terms)

        formData.uploadimg.forEach((file) => {
            formDataToSend.append('uploadimg', file);
        });

        try {
            const response = await axios.post(`${API_URL}/api/campaign/create_campaign`, formDataToSend, {
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    setUploadProgress(progress)
                }
            })

            if (response.status === 200) {
                toast.success('Campaign created successfully!')
                window.location.href = '/createcampaign'
            } else {
                toast.error('Failed to create campaign. Please try again.')
            }
        } catch (error) {
            toast.error('Error creating campaign. Please try again later.')
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className='bg-sky-500 min-h-screen w-full flex items-start sm:items-center justify-center p-4 sm:px-6 lg:px-8 py-4 sm:py-8'>
            <div className="w-full max-w-3xl bg-white rounded-lg shadow-md my-4 sm:my-6">
                <div className="p-3 sm:p-6 md:p-8">
                    <h1 className="text-xl sm:text-2xl md:text-3xl font-bold text-gray-800 text-center mb-4 sm:mb-6 md:mb-8">
                        Create New Campaign
                    </h1>

                    <form onSubmit={handleSubmit} className="space-y-3 sm:space-y-4 md:space-y-6">
                        {/* Input Field Component */}
                        {inputField("Title", "name", "text")}

                        {/* Image Upload Section */}
                        <div className="space-y-1 sm:space-y-2">
                            <label className="block text-sm font-medium text-gray-700">
                                Upload AD Image
                            </label>
                            <div
                                {...getRootProps()}
                                className={`relative w-full px-3 sm:px-4 py-6 sm:py-8 border-2 border-dashed rounded-lg transition-colors duration-200 ${isDragActive ? 'border-blue-400' : 'border-gray-300'
                                    }`}
                            >
                                <input {...getInputProps()} name="uploadimg" multiple />
                                <div className="text-center">
                                    {formData.uploadimg.length > 0 ? (
                                        <div className="space-y-1">
                                            {formData.uploadimg.map((file, index) => (
                                                <p key={index} className="text-xs sm:text-sm truncate">
                                                    {file.name}
                                                </p>
                                            ))}
                                        </div>
                                    ) : (
                                        <p className="text-xs sm:text-sm text-gray-500">
                                            Drag & Drop your images here or click to select files
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Progress Bar */}
                        {uploadProgress > 0 && (
                            <div className="space-y-2">
                                <label className="block text-sm font-medium text-gray-700">
                                    Upload Progress
                                </label>
                                <progress
                                    value={uploadProgress}
                                    max="100"
                                    className="w-full h-2 rounded"
                                />
                            </div>
                        )}

                        {/* Basic Fields */}
                        {inputField("Campaigns", "campaigns", "text")}
                        {inputField("Apply Before", "apply_before", "date")}
                        {inputField("Campaign Type", "campaign_type", "text")}
                        {inputField("Earnings", "earnings", "text")}
                        {inputField("Age Group", "age_group", "text")}
                        {inputField("Gender", "gender", "text")}
                        {inputField("Language", "language", "text")}
                        {inputField("Target Peoples", "peoples", "text")}
                        {inputField("Suitable Channels", "channels", "text")}
                        {inputField("Brand / Company Name", "brandname", "text")}
                        {inputField("Hint", "hint", "text")}

                        {/* Textarea Fields */}
                        {textareaField("Description", "description")}
                        {textareaField("Campaign Brief", "brief")}
                        {textareaField("Terms", "terms")}

                        {/* Submit Button */}
                        <div className="pt-4 sm:pt-6">
                            {loading ? (
                                <div className="flex justify-center">
                                    <PuffLoader color="#3b82f6" size={60} loading={loading} />
                                </div>
                            ) : (
                                <button
                                    type="submit"
                                    className="w-full py-2.5 sm:py-3 px-4 sm:px-6 text-white bg-blue-600 rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 transition duration-300 text-sm sm:text-base font-medium"
                                >
                                    Create Campaign
                                </button>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );

    // Helper function for input fields
    function inputField(label, name, type) {
        return (
            <div className="space-y-1 sm:space-y-2">
                <label className="block text-xs sm:text-sm font-medium text-gray-700">
                    {label}
                </label>
                <input
                    type={type}
                    name={name}
                    onChange={handleChange}
                    className="w-full px-2.5 sm:px-3 py-1.5 sm:py-2 text-xs sm:text-sm border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent transition-colors duration-200"
                />
            </div>
        );
    }

    // Helper function for textarea fields
    function textareaField(label, name) {
        return (
            <div className="space-y-1 sm:space-y-2">
                <label className="block text-xs sm:text-sm font-medium text-gray-700">
                    {label}
                </label>
                <textarea
                    name={name}
                    onChange={handleChange}
                    rows={4}
                    className="w-full px-2.5 sm:px-3 py-1.5 sm:py-2 text-xs sm:text-sm border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent transition-colors duration-200 resize-none"
                />
            </div>
        );
    }
}
