import Modal from 'react-modal';
import { HiX } from "react-icons/hi";

export const PopUpModel = ({ data, isOpen, onClose }) => {
    const close = () => {
        onClose('param', 'param2', 'param3');
    }

    return (
        <Modal
            isOpen={isOpen}
            className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 p-4 sm:p-6"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
        >
            <div className="bg-white rounded-lg shadow-xl w-full max-w-lg mx-auto max-h-[90vh] overflow-y-auto">
                {/* Header */}
                <div className="flex justify-between items-center p-4 sm:p-6 border-b">
                    <h2 className="text-lg sm:text-xl font-bold text-gray-900">Company Details</h2>
                    <button
                        onClick={close}
                        className="text-gray-500 hover:text-gray-700 transition-colors duration-200"
                    >
                        <HiX className="h-5 w-5 sm:h-6 sm:w-6" />
                    </button>
                </div>

                {/* Content */}
                <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">
                    <DetailRow label="Company Name" value={data.company_name} />
                    <DetailRow label="Contact Person Name" value={data.contact_person_name} />
                    <DetailRow label="Email ID" value={data.email_id} />
                    <DetailRow label="Mobile Number" value={data.mobile_number} />
                    <DetailRow label="Company Industry Type" value={data.company_industry_type} />
                    <DetailRow label="Brand or Agent" value={data.brand_or_agent} />
                    <DetailRow label="Job Title" value={data.job_title} />
                    <DetailRow label="Country" value={data.country} />
                    <DetailRow label="State" value={data.state} />
                    <DetailRow label="City" value={data.city} />
                    <DetailRow label="Address" value={data.address} />
                    <DetailRow label="Pincode" value={data.pincode} />
                    <DetailRow label="Website" value={data.website} />
                    <DetailRow label="GST Number" value={data.gst_number} />
                </div>

                {/* Footer */}
                <div className="border-t p-4 sm:p-6 flex justify-end">
                    <button
                        onClick={close}
                        className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition-colors duration-200 text-sm sm:text-base font-medium"
                    >
                        Close
                    </button>
                </div>
            </div>
        </Modal>
    );
}

// Helper component for detail rows
const DetailRow = ({ label, value }) => (
    <div className="flex flex-col sm:flex-row sm:items-center gap-1 sm:gap-4">
        <label className="text-sm font-medium text-gray-500 sm:w-1/3">{label}:</label>
        <span className="text-sm sm:text-base text-gray-900 sm:w-2/3 break-words">
            {value || '-'}
        </span>
    </div>
);
