import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { API_URL } from '../../constant/constance'
import { toast } from 'react-toastify'
import DataTable from 'react-data-table-component'
import { ImCheckmark, ImCross } from "react-icons/im";

function Brand() {
    const [brandData, setBrandData] = useState([])
    const [isupdated, setIsupdated] = useState(false)
    const [filterText, setFilterText] = useState('')
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false)

    const filteredItems = brandData.filter(
        (item) => item.email_id && item.email_id.toLowerCase().includes(filterText.toLowerCase())
    )

    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            width: '50px'
        },
        {
            name: 'Company Name',
            selector: (row) => row.company_name,
            maxWidth: '200px',
            wrap: true
        },
        {
            name: 'Person Name',
            selector: (row) => row.contact_person_name,
            maxWidth: '200px',
            wrap: true
        },
        {
            name: 'Job Title',
            selector: (row) => row.job_title,
            maxWidth: '200px',
            wrap: true
        },
        {
            name: 'Brand/Agent',
            selector: (row) => row.brand_or_agent,
            maxWidth: '200px',
            wrap: true
        },
        {
            name: 'Country',
            selector: (row) => row.country,
            maxWidth: '200px',
            wrap: true
        },
        {
            name: 'Email',
            selector: (row) => row.email_id,
            maxWidth: '200px',
            wrap: true
        },
        {
            name: 'Action',
            cell: (row) => (
                <div className="flex items-center gap-2">
                    <button
                        type="button"
                        className="rounded px-2 py-1 text-red-600 hover:bg-red-50"
                        onClick={() => handleReject(row._id)}
                    >
                        <ImCross />
                    </button>
                    <button
                        type="button"
                        className="rounded px-2 py-1 text-green-600 hover:bg-green-50"
                        onClick={() => handleAprovel(row._id)}
                    >
                        <ImCheckmark />
                    </button>
                </div>
            ),
            width: '100px'
        },
    ]

    const sendEmail = (userId) => {
        axios
            .post(`${API_URL}/api/brandreg/send-email`, { user_id: userId })
            .then((response) => {
                console.log('Email sent successfully:', response.data)
                window.location.reload()
            })
            .catch((error) => {
                console.error('Error sending email:', error)
            })
    }

    useEffect(() => {
        const fetchBrandData = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/brandreg`)
                setBrandData(response.data.data)
            } catch (error) {
                console.error('Error fetching brand data:', error)
            }
        }

        fetchBrandData()
    }, [isupdated])

    const handleReject = async (itemId) => {
        try {
            const response = await axios.get(`${API_URL}/api/brandreg/reject_brand/${itemId}`)
            if (response.status === 200) {
                toast.success(response.data.message)
                setIsupdated((prev) => !prev)
            }
        } catch (error) {
            toast.error(error.message)
            console.error('Error', error)
        }
    }

    const handleAprovel = async (itemId) => {
        try {
            const response = await axios.get(`${API_URL}/api/brandreg/aprover_brand/${itemId}`)
            if (response.status === 200) {
                toast.success(response.data.message)
                setIsupdated((prev) => !prev)
            }
        } catch (error) {
            toast.error(error.message)
            console.error('Error', error)
        }
    }

    const customStyles = {
        table: {
            style: {
                backgroundColor: 'white',
            },
        },
        rows: {
            style: {
                minHeight: '56px',
                padding: '8px',
                fontSize: '14px',
                '&:hover': {
                    backgroundColor: '#f9fafb',
                },
            },
        },
        headRow: {
            style: {
                backgroundColor: '#f8f9fa',
                borderBottom: '1px solid #e5e7eb',
                minHeight: '48px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px',
                paddingRight: '8px',
                fontWeight: 'bold',
            },
        },
        cells: {
            style: {
                paddingLeft: '8px',
                paddingRight: '8px',
            },
        },
    };

    // Custom component for mobile/tablet view
    const MobileCard = ({ data, index }) => (
        <div className="bg-white p-4 sm:p-5 rounded-lg shadow-sm border border-gray-200 space-y-4">
            <div className="flex justify-between items-center">
                <span className="text-base font-semibold text-gray-600">#{index + 1}</span>
                <div className="flex items-center gap-3">
                    <button
                        type="button"
                        className="rounded p-2 text-red-600 hover:bg-red-50"
                        onClick={() => handleReject(data._id)}
                    >
                        <ImCross className="w-4 h-4" />
                    </button>
                    <button
                        type="button"
                        className="rounded p-2 text-green-600 hover:bg-green-50"
                        onClick={() => handleAprovel(data._id)}
                    >
                        <ImCheckmark className="w-4 h-4" />
                    </button>
                </div>
            </div>

            <div className="space-y-3 sm:space-y-4">
                <DataRow label="Company Name" value={data.company_name} />
                <DataRow label="Person Name" value={data.contact_person_name} />
                <DataRow label="Job Title" value={data.job_title} />
                <DataRow label="Brand/Agent" value={data.brand_or_agent} />
                <DataRow label="Country" value={data.country} />
                <DataRow label="Email" value={data.email_id} />
            </div>
        </div>
    );

    // Helper component for each data row in mobile view
    const DataRow = ({ label, value }) => (
        <div className="flex flex-col gap-1">
            <span className="text-sm font-medium text-gray-500">{label}</span>
            <span className="text-base text-gray-900">{value || '-'}</span>
        </div>
    );

    return (
        <div className="flex flex-col gap-4 w-full transition-all duration-300">
            {/* Search Input */}
            <div className="px-2 sm:px-4">
                <input
                    type="text"
                    placeholder="Search by Email ID"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                    className="p-2 sm:p-3 text-base sm:text-lg border rounded-lg w-full max-w-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300"
                />
            </div>

            {/* Mobile/Tablet View with Grid Layout */}
            <div className="lg:hidden px-2 sm:px-4 transition-all duration-300">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4">
                    {(filterText.length > 0 ? filteredItems : brandData).map((item, index) => (
                        <MobileCard key={item._id} data={item} index={index} />
                    ))}
                </div>
            </div>

            {/* Desktop View (Original Table) */}
            <div className="hidden lg:block px-2 sm:px-4 transition-all duration-300">
                <div className="border rounded shadow-sm overflow-hidden">
                    <DataTable
                        title="Brand"
                        columns={columns}
                        data={filterText.length > 0 ? filteredItems : brandData}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle}
                        customStyles={customStyles}
                        striped
                        highlightOnHover
                        fixedHeader
                    />
                </div>
            </div>
        </div>
    );
}

export default Brand