import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { API_URL } from '../../constant/constance'
import { toast } from 'react-toastify'
import DataTable from 'react-data-table-component'
import { usePopupManager } from 'react-popup-manager'
import { PopUpModel } from '../../components/shared/PopUpModel'
import { ImEye, ImCross } from "react-icons/im";

function Brandapprove() {
    const [newuser, setNewuser] = useState(0)
    const [AproverData, setAproverData] = useState([])
    const [Detail, setDetail] = useState([])
    const [filterText, setFilterText] = useState('')
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false)

    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            sortable: true,
            width: '80px',
            style: {
                paddingLeft: '24px',
            },
            headCell: {
                style: {
                    paddingLeft: '24px',
                },
            },
        },
        {
            name: 'Company Name',
            selector: (row) => row.company_name,
            style: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '200px'
            }
        },
        {
            name: 'Person Name',
            selector: (row) => row.contact_person_name,
            style: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '200px'
            }
        },
        {
            name: 'Job title',
            selector: (row) => row.job_title,
            style: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '200px'
            }
        },
        {
            name: 'Brand/Agent',
            selector: (row) => row.brand_or_agent,
            style: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '100px'
            }
        },
        {
            name: 'Country',
            selector: (row) => row.country,
            style: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '130px'
            }
        },
        {
            name: 'Email',
            selector: (row) => row.email_id,
            style: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '200px'
            }
        },
        {
            name: 'Action',
            cell: (row) => (
                <div className="flex items-center gap-x-2">
                    <button
                        type="submit"
                        className="rounded-md px-2 py-2 font-semibold text-red-600 shadow-sm"
                        onClick={() => handleReject(row._id)}
                    >
                        <ImCross />
                    </button>
                    <button
                        type="submit"
                        className="rounded-md px-2 py-2 font-semibold text-green-600 shadow-sm"
                        onClick={() => handleDetails(row._id)}
                    >
                        <ImEye />
                    </button>
                </div>
            )
        }
    ]

    useEffect(() => {
        const fetchNewUser = async () => {
            try {
                const startDate = new Date()
                startDate.setDate(startDate.getDate() - 7)
                const endDate = new Date()
                const responce = await axios.get(`${API_URL}/api/brandreg/new_users-count`, {
                    params: {
                        startDate: startDate.toISOString(),
                        endDate: endDate.toISOString()
                    }
                })
                setNewuser(responce.data.count)
            } catch (error) {
                console.error('Error fetching new user:', error)
            }
        }
        fetchNewUser()
    }, [])

    useEffect(() => {
        const fetchBrandData = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/brandreg/approved`)
                setAproverData(response.data.data)
            } catch (error) {
                console.error('Error fetching brand data:', error)
            }
        }

        fetchBrandData()
    }, [])

    const filteredItems = AproverData.filter(
        (item) => item.email_id && item.email_id.toLowerCase().includes(filterText.toLowerCase())
    )

    const popupManager = usePopupManager()
    const openModal = (Data) => {
        popupManager.open(PopUpModel, {
            data: Data,
        })
    }

    const handleReject = (itemId) => {
        try {
            const response = axios.get(`${API_URL}/api/brandreg/reject_brand/${itemId}`)
            if (response.status === 200) {
                toast.success(response.data.message)
            }
        } catch (error) {
            toast.error(error.message)
            console.error('Error', error)
        }
    }

    const handleDetails = async (itemId) => {
        try {
            const response = await axios.get(`${API_URL}/api/brandreg/Brand_details/${itemId}`)
            if (response.status === 200) {
                setDetail(response.data.data)
                openModal(response.data.data)
            }
        } catch (error) {
            toast.error(error.message)
            console.error('Error', error)
        }
    }

    // Add MobileCard component for mobile/tablet view
    const MobileCard = ({ data, index }) => (
        <div className="bg-white p-3 sm:p-4 md:p-5 rounded-lg shadow-sm border border-gray-200 transition-all duration-300 hover:shadow-md overflow-hidden">
            {/* Header Section */}
            <div className="flex justify-between items-center mb-4">
                <span className="text-sm sm:text-base font-semibold text-gray-600">#{index + 1}</span>
                <div className="flex items-center gap-2 sm:gap-3">
                    <button
                        type="button"
                        className="rounded p-1.5 sm:p-2 text-red-600 hover:bg-red-50 transition-colors duration-300"
                        onClick={() => handleReject(data._id)}
                    >
                        <ImCross className="w-3 h-3 sm:w-4 sm:h-4" />
                    </button>
                    <button
                        type="button"
                        className="rounded p-1.5 sm:p-2 text-green-600 hover:bg-green-50 transition-colors duration-300"
                        onClick={() => handleDetails(data._id)}
                    >
                        <ImEye className="w-3 h-3 sm:w-4 sm:h-4" />
                    </button>
                </div>
            </div>

            {/* Content Section */}
            <div className="grid gap-2.5 sm:gap-3 md:gap-4">
                <DataRow label="Company Name" value={data.company_name} />
                <DataRow label="Person Name" value={data.contact_person_name} />
                <DataRow label="Job Title" value={data.job_title} />
                <DataRow label="Brand/Agent" value={data.brand_or_agent} />
                <DataRow label="Country" value={data.country} />
                <DataRow label="Email" value={data.email_id} />
            </div>
        </div>
    );

    // Helper component for each data row in mobile view
    const DataRow = ({ label, value }) => (
        <div className="flex flex-col gap-0.5 sm:gap-1">
            <span className="text-xs sm:text-sm font-medium text-gray-500">{label}</span>
            <span className="text-sm sm:text-base text-gray-900 break-words">
                {value || '-'}
            </span>
        </div>
    );

    // Updated customStyles for desktop view
    const customStyles = {
        table: {
            style: {
                backgroundColor: 'white',
                transition: 'all 0.3s ease-in-out',
            },
        },
        rows: {
            style: {
                minHeight: '52px',
                fontSize: '14px',
                backgroundColor: 'white',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                    backgroundColor: '#f9fafb',
                },
                borderBottom: '1px solid #f0f0f0',
            },
        },
        headRow: {
            style: {
                backgroundColor: '#f8f9fa',
                minHeight: '48px',
                borderBottom: '2px solid #e5e7eb',
                transition: 'all 0.3s ease-in-out',
            },
        },
        headCells: {
            style: {
                fontSize: '14px',
                fontWeight: '600',
                padding: '12px 24px',
                transition: 'all 0.3s ease-in-out',
            },
            firstChild: {
                paddingLeft: '24px',
                minWidth: '70px',
            },
        },
        cells: {
            style: {
                padding: '12px 24px',
                transition: 'all 0.3s ease-in-out',
            },
            firstChild: {
                paddingLeft: '24px',
                minWidth: '70px',
            },
        },
    };

    return (
        <div className="flex flex-col gap-4 w-full transition-all duration-300">
            {/* New Users Count */}
            <div className="px-2 sm:px-4">
                <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200">
                    <h1 className="text-lg font-semibold text-gray-800">
                        New Users in the Last 7 Days: {newuser}
                    </h1>
                </div>
            </div>

            {/* Search Input */}
            <div className="px-2 sm:px-4">
                <input
                    type="text"
                    placeholder="Search by Email ID"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                    className="p-2 sm:p-3 text-base sm:text-lg border rounded-lg w-full max-w-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300"
                />
            </div>

            {/* Updated Mobile/Tablet View with better grid responsiveness */}
            <div className="xl:hidden px-2 sm:px-4 transition-all duration-300">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 sm:gap-4">
                    {(filterText.length > 0 ? filteredItems : AproverData).map((item, index) => (
                        <MobileCard key={item._id} data={item} index={index} />
                    ))}
                </div>
            </div>

            {/* Desktop View */}
            <div className="hidden xl:block px-2 sm:px-4 transition-all duration-300">
                <div className="border rounded shadow-sm overflow-hidden">
                    <DataTable
                        title="Brand"
                        columns={columns}
                        data={filterText.length > 0 ? filteredItems : AproverData}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle}
                        customStyles={customStyles}
                        striped={false}
                        highlightOnHover
                    />
                </div>
            </div>
        </div>
    );
}

export default Brandapprove;
