import React from 'react'
import { FiEdit2, FiX } from 'react-icons/fi';

export default function Contactus() {
    return (
        <div>
            <div className="bg-white px-4 pt-3 pb-4 rounded-sm  flex-1">
                <div className="flex flex-row items-center gap-x-6 justify-between">
                    <h2 className="text-gray-700 font-medium gap-x-6">All Contactus</h2>
                </div>
                <div className="border-x border-gray-200 rounded-sm mt-3">
                    <table className="w-full text-gray-700">
                        <thead>
                            <tr>
                                <th className="text-sm font-medium">Name</th>
                                <th className="text-sm font-medium">Email</th>
                                <th className="text-sm font-medium">Question</th>
                                <th className="text-sm font-medium">Comment</th>
                                <th className="text-sm font-medium">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>asd</td>
                                <td>asd</td>
                                <td>adsd</td>
                                <td>adsd</td>
                                <td>
                                    <div className="flex items-center gap-x-3">
                                        <button
                                            type="submit"
                                            className="rounded-md px-2 py-2 font-semibold text-green-600 shadow-sm"
                                        >
                                            <FiEdit2 width={50} />
                                        </button>
                                        <button
                                            type="submit"
                                            className="rounded-md  px-2 py-2 font-semibold text-red-600 shadow-sm shadow-lime-500"
                                        >
                                            <FiX width={50} />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
